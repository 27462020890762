<template>
  <div class="container">
    <div class="top">
      <div class="web-name">中邦指数(山东)信息科技有限公司</div>
      <nav>
        <router-link to="/" class="main">主页</router-link>
        <router-link to="/about">联系我们</router-link>
      </nav>
    </div>
    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<style lang="less">
#app {
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgba(74, 153, 211, 0.2),
    rgba(74, 153, 211, 0)
  );

  .container {
    .top {
      display: flex;
      justify-content: space-between;
      padding: 60px 0;
      margin: 0 auto;
      align-items: center;
      width: 1440px;
      .web-name {
        color: #4a99d3;
        font-weight: bold;
        font-size: 24px;
      }
      nav {
        a {
          // font-weight: bold;
          color: #0f001a;
          text-decoration: none;
          &.main {
            margin-right: 60px;
          }
          &.router-link-exact-active {
            color: #4a99d3;
          }
        }
      }
    }
    .content {
      // padding: 0 160px;
    }
  }
}
::-webkit-scrollbar {
  display: none;
  background-color: transparent; /* 背景色为透明 */
  border: none; /* 没有边框 */
}
</style>
