<template>
  <div class="main-page">
    <div class="top-page">
      <div class="home">
        <div class="home-left">
          <p class="left-name">中医智慧医疗平台<br />文道中医</p>
          <p class="left-intro">
            定位：专用于基层诊所的智慧诊疗、综合管理平台
            <br />
            作用：提升用户数量、提高医疗水平、提高盈利能力、就诊方便价廉
            <br />
            用户：诊所、医师、患者、药品供应商、中医学生、养老机构、社区物业服务机构；
          </p>
          <div class="btn">敬请期待</div>
        </div>
        <div class="home-right">
          <img src="../assets/1.png" alt="" />
        </div>
      </div>
      <div class="second">
        <div class="second-title">强大的智慧医疗功能模块</div>
        <img class="pot1 pot" src="../assets/pot.png" alt="" />
        <img class="pot2 pot" src="../assets/pot.png" alt="" />
        <img class="pot3 pot" src="../assets/pot.png" alt="" />

        <div class="item item1">
          <div class="img">
            <img src="../assets/yyqt.png" alt="" />
          </div>
          <div class="item-name">医师诊疗的得力助手</div>
          <p class="detail">
            诊所医师问诊+后台顾问咨询，帮助医师进行精准诊断。系统记录每次就诊的诊疗过程，包括对患者的询问、望闻问切结果和诊断建议等。依据诊断结果，系统自动生成药方，包括中药材和药品的配方和用法等，供医师参考选用。
          </p>
        </div>
        <div class="item item2">
          <div class="img">
            <img src="../assets/hy.png" alt="" />
          </div>
          <div class="item-name">诊所智慧管理的工具</div>
          <p class="detail">
            诊所医师问诊+医师管理、患者管理、药品药材进销存管理、收费管理等。患者在线付费获取药方/根据医师开具的药方在平台上付费取药。诊所可以根据自己的需要选定最优供应方。供应方可以实时掌握各诊所药品的消耗情况，进行智能补充。直供配送至诊所。
          </p>
        </div>
        <div class="item item3">
          <div class="img">
            <img src="../assets/yxyj.png" alt="" />
          </div>
          <div class="item-name">
            智慧型疾病预防
            <br />
            诊疗和前瞻性指导的晴雨表
          </div>
          <p class="detail">
            诊所医师问诊+揭示人、病、方、药的内在关系和变化趋势，以大数据分析报告的形式动态地展示给诊所、医师、百姓、药材供应方等，为其提供前瞻性指导。
          </p>
        </div>
      </div>
    </div>
    <div class="third">
      <div class="third-title">平台的优势</div>
      <ul class="list">
        <li class="single-item">
          <img src="../assets/ys1.png" alt="" />
          <p class="name">自助开合功能</p>
          <p class="intro">
            实现“医师、问诊、知识、技术、药方、药材”等各类信息的交流和共享
          </p>
        </li>
        <li class="single-item">
          <img src="../assets/ys2.png" alt="" />
          <p class="name">医患在线实时沟通功能</p>
          <p class="intro">提升了疗效，又提高了患者对诊所的信任度和服务粘性</p>
        </li>
        <li class="single-item">
          <img src="../assets/ys3.png" alt="" />
          <p class="name">药企—诊所、智慧配送功能</p>
          <p class="intro">
            药企可以通过智慧管理系统，向各诊所直接配送所需药品实现智能调配和结算
          </p>
        </li>
      </ul>
    </div>
    <div class="fourth">
      <div class="fourth-title">联合推广机制</div>
      <div class="fourth-one">
        <div class="one-left">
          <img src="../assets/person.png" alt="" class="person" />
        </div>
        <div class="one-middle">
          <div class="one-middle-top">
            <img src="../assets/quote-left.png" alt="" />
          </div>
          <div class="one-middle-middle">
            本平台与多个专业平台建立了合作推广机制，可以快速地把系统推广至各基层医疗机构、社会各阶层用户，为诊所提供更多的注册群体。
            （1）联合药材供应方、基层诊所，向各类药企争取更优惠的药品药材价格。
            （2）联合卫健委、各医学院举办多种竞赛活动，推介诊所的医师、打造“名医”、“名所”，提升诊所的地位、促进中医学生的就业。
          </div>
          <div class="one-middle-bottom">
            <img src="../assets/quote-right.png" alt="" />
          </div>
        </div>
        <div class="one-right">
          <img src="../assets/pot.png" alt="" />
        </div>
      </div>
      <div class="fourth-two">
        <div class="left-img">
          <img src="../assets/img.png" alt="">
        </div>
        <div class="right-intro">
          <div class="intro-title">强大的“1”套后台支撑系统</div>
          <p class="intro-details">
            向所有用户提供全天候、24小时的功能升级和运维支持，保障平台的稳定运行，数据的流畅传输，功能的不断完善。 集理论与实践于一身的中西医医师团队，对用户的需求进行整理，进行专业咨询，提供在线帮助。
          </p>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="bottom-top">
        <div class="name">中邦指数(山东)信息科技有限公司</div>
        <div class="top-right">
          <p>留下您的联系方式以便我们与您沟通</p>
          <div class="submit">
            <input type="text" placeholder="邮箱地址">
            <button>
              提交
            </button>
          </div>
        </div>
      </div>
      <div class="bottom-bottom">
        Copyright © 2024 zbzssd.com All Rights Reserved
        &nbsp;&nbsp;&nbsp;&nbsp;备案号：<a target="\_blank" href="https://beian.miit.gov.cn/">鲁ICP备2024070202号-1</a> 
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "HomeView",
};
</script>
<style lang="less" scoped>
.main-page {
  .top-page {
    width: 1440px;
    margin: 0 auto;
    padding: 0 160px;
    box-sizing: border-box;
    .home {
      display: flex;
      align-items: center;
      .home-right {
        width: 600px;
        img {
          width: 100%;
        }
      }
      .home-left {
        flex-grow: 1;
        margin-right: 20px;
        .left-name {
          color: #260041;
          font-size: 50px;
        }
        .left-intro {
          color: #260041;
          font-size: 18px;
          line-height: 30px;
          margin-top: 20px;
        }
        .btn {
          margin-top: 20px;
          background-color: #4a99d3;
          color: #fff;
          display: inline-block;
          width: 180px;
          height: 60px;
          line-height: 60px;
          text-align: center;
          border-radius: 5px;
        }
      }
    }
    .second {
      padding: 100px 0px;
      width: 100%;
      height: 1000px;
      position: relative;
      .second-title {
        color: #260041;
        font-size: 32px;
        font-weight: bold;
        position: absolute;
        top: 150px;
      }
      .pot {
        width: 150px;
        position: absolute;
        &.pot1 {
          right: -75px;
        }
        &.pot2 {
          left: -75px;
          top: 550px;
        }
        &.pot3 {
          right: -25px;
          top: 1000px;
        }
      }
      .item {
        width: 280px;
        background-color: #fff;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;
        border-radius: 10px;
        box-sizing: border-box;
        position: absolute;
        box-shadow: 5px 10px 30px #4a99d3;
        &.item1 {
          right: 0;
          top: 175px;
        }
        &.item2 {
          left: 0;
          top: 420px;
        }
        &.item3 {
          right: 0;
          top: 700px;
        }
        .img {
          text-align: center;
          img {
            width: 240px;
          }
        }
        .item-name {
          margin-bottom: 20px;
          color: #0f001a;
          font-weight: bold;
          font-size: 18px;
        }
        .detail {
          color: #0f001a;
          opacity: 0.6;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
  .third {
    height: 450px;
    width: 100%;
    background-color: rgba(237, 245, 251, 1);
    padding: 100px 400px;
    box-sizing: border-box;
    .third-title {
      text-align: center;
      font-weight: bold;
      font-size: 24px;
      color: #4a99d3;
      margin-bottom: 50px;
    }
    .list {
      list-style: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .single-item {
        width: 240px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          width: 100px;
        }
        .name {
          color: #0f001a;
          font-weight: bold;
          font-size: 18px;
          margin: 20px 0;
        }
        .intro {
          color: #0f001a;
          font-size: 14px;
          text-align: center;
        }
      }
    }
  }
  .fourth {
    .fourth-title {
      font-size: 24px;
      color: #0f001a;
      text-align: center;
      padding: 150px 0;
      font-weight: bold;
    }
    .fourth-one {
      display: flex;
      height: 420px;
      align-items: center;
      .one-left {
        width: 1000px;
        flex-shrink: 0;
        height: 100%;
        background-color: rgba(237, 245, 251, 1);
        position: relative;
        img {
          position: absolute;
          bottom: 0;
          height: 525px;
          right: 100px;
        }
      }
      .one-middle {
        flex-grow: 1;
        margin: 0 50px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        img {
          width: 60px;
        }
        .one-middle-middle {
          font-size: 14px;
          color: #0F001A;
          opacity: 50%;
          line-height: 24px;
        }
        .one-middle-bottom {
          text-align: right;
        }
      }
      .one-right {
        width: 160px;
        flex-shrink: 0;
        img {
          width: 100%;
        }
      }
    }
    .fourth-two {
      display: flex;
      padding: 180px 210px;
      align-items: center;
      .left-img {
        width: 45%;
        flex-shrink: 0;
        margin-right: 120px;
        img {
          width: 100%;
        }
      }
      .right-intro {
        width: 45%;
        flex-shrink: 0;
        text-align: center;
        .intro-title {
          font-size: 22px;
          font-weight: bold;
          color: #0F001A;
          margin-bottom: 30px;
        }
        .intro-details {
          color: #0F001A;
          opacity: 50%;
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
  .bottom {
    position: fixed;
    width: 100%;
    bottom: 0;
    height: 200px;
    background-color: #4A99D3;
    opacity: 0.5;
    display: flex;
    flex-direction: column;
    .bottom-bottom {
      height: 50px;
      text-align: center;
      font-size: 12px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 50px;
      a {
      list-style: none;
      text-decoration: none;
      color: #fff;
    }
    }
    .bottom-top {
      flex-grow: 1;
      border-bottom: 1px solid #fff;
      padding: 30px 210px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      .name {
        color: #fff;
        font-size: 22px;
        font-weight: bold;
        
      }
      .top-right {
        p {
           color: #fff;
           font-size: 16px;
           margin-bottom: 20px;
        }
        .submit {
          display: flex;
          align-items: center;
          input {
            height: 35px;
            padding: 0 10px;
            border: 0;
            border-radius: 3px;
            width: 200px;
            outline: none;
          }
          button {
            height: 35px;
            border: 0;
            margin-left: 20px;
            width: 80px;
            background-color: #FFFFFF;
            color: #4A99D3;
            font-size: 14px;
            font-weight: bold;
            border-radius: 3px;
          }
        }
      }
    }
  }
}
</style>
